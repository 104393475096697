import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "home-view",
  id: "particles-js"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hero_section = _resolveComponent("hero-section")!
  const _component_contact_section = _resolveComponent("contact-section")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_hero_section),
    _createVNode(_component_contact_section)
  ]))
}