<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="536"
        height="412"
        viewBox="0 0 594.905 459.484"
    >
        <defs>
            <linearGradient
                id="linear-gradient"
                x1="14.929"
                y1="1"
                x2="14.929"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stop-color="gray" stop-opacity="0.251" />
                <stop offset="0.54" stop-color="gray" stop-opacity="0.122" />
                <stop offset="1" stop-color="gray" stop-opacity="0.102" />
            </linearGradient>
        </defs>
        <g id="Group_1" data-name="Group 1" transform="translate(0.009 0.008)">
            <ellipse
                id="Ellipse_11"
                data-name="Ellipse 11"
                cx="127.234"
                cy="19.286"
                rx="127.234"
                ry="19.286"
                transform="translate(58.513 420.904)"
                fill="#fc4445"
                opacity="0.1"
            />
            <path
                id="Path_10"
                data-name="Path 10"
                d="M228.46,41.428C248.641,23.642,280.324,20,309.167,21.435,399.747,25.892,481.5,69.109,552.247,118c25.608,17.706,50.819,36.777,67.437,61.158,33.836,49.619,23.8,118.363-23.25,159.239-16.007,13.929-35.411,24.574-55.33,34.024-35.422,16.806-73.641,30.3-113.841,34.136-28.667,2.738-57.687.509-86.187-3.311-79.63-10.672-157.792-34.286-223.3-74.664C89.044,310.9,61.963,288.858,50.118,260.2S45.832,195.235,75.2,178.279c12.134-7.018,26.642-10.179,40.844-13.163,20.893-4.377,42.151-8.695,61.019-17.593,19.484-9.2,42.558-26.979,38.7-47.326C211.617,78.356,208.285,59.182,228.46,41.428Z"
                transform="translate(-44.77 -21.16)"
                fill="#fc4445"
                opacity="0.25"
            />
            <path
                id="Path_11"
                data-name="Path 11"
                d="M942.356,79.435c3.621,23.872-6.455,45.2-6.455,45.2s-15.948-17.374-19.581-41.245c-2.03-13.393.246-25.972,2.636-34.447a5.893,5.893,0,0,1,10.656-1.607C934.416,54.706,940.325,66.047,942.356,79.435Z"
                transform="translate(-449.049 -32.048)"
                fill="#fc4445"
            />
            <path
                id="Path_12"
                data-name="Path 12"
                d="M1070.747,180.64a41.2,41.2,0,0,1,10.634,1.345,5.906,5.906,0,0,1,.123,11.406,41.331,41.331,0,0,1-10.6,1.575c-13.141.145-23.872-6.906-23.872-6.906S1057.605,180.784,1070.747,180.64Z"
                transform="translate(-510.099 -95.206)"
                fill="#fc4445"
            />
            <path
                id="Path_13"
                data-name="Path 13"
                d="M1009.478,100.9C997.248,114.338,994.66,131.9,994.66,131.9s17.24-4.232,29.465-17.679a60.869,60.869,0,0,0,11.5-19.184,5.893,5.893,0,0,0-8.1-7.393A60.52,60.52,0,0,0,1009.478,100.9Z"
                transform="translate(-485.785 -51.753)"
                fill="#fc4445"
            />
            <rect
                id="Rectangle_5"
                data-name="Rectangle 5"
                width="275.362"
                height="331.597"
                rx="19.8"
                transform="translate(273.997 46.61)"
                fill="#464353"
            />
            <rect
                id="Rectangle_6"
                data-name="Rectangle 6"
                width="227.854"
                height="264.696"
                transform="translate(297.751 80.06)"
                fill="#fc4445"
                opacity="0.1"
            />
            <circle
                id="Ellipse_12"
                data-name="Ellipse 12"
                cx="9.697"
                cy="9.697"
                r="9.697"
                transform="translate(401.982 352.514)"
                fill="#fff"
            />
            <path
                id="Path_14"
                data-name="Path 14"
                d="M809.26,144.981a4.58,4.58,0,1,1,4.58-4.58,4.58,4.58,0,0,1-4.58,4.58Z"
                transform="translate(-397.582 -74.397)"
                fill="#fff"
            />
            <path
                id="Path_15"
                data-name="Path 15"
                d="M809.005,135.856a4.313,4.313,0,1,1-4.313,4.313,4.313,4.313,0,0,1,4.313-4.313m0-.536a4.822,4.822,0,1,0,3.419,1.422A4.848,4.848,0,0,0,809.005,135.32Z"
                transform="translate(-397.327 -74.165)"
                fill="#fff"
            />
            <rect
                id="Rectangle_7"
                data-name="Rectangle 7"
                width="47.256"
                height="64.244"
                rx="4.3"
                transform="translate(308.337 162.058)"
                fill="#fff"
                stroke="#fff"
                stroke-miterlimit="10"
                stroke-width="1"
            />
            <rect
                id="Rectangle_8"
                data-name="Rectangle 8"
                width="38.395"
                height="20.384"
                rx="7.14"
                transform="translate(312.768 178.248)"
                fill="#fc4445"
                opacity="0.5"
            />
            <rect
                id="Rectangle_9"
                data-name="Rectangle 9"
                width="22.366"
                height="3.166"
                rx="1.583"
                transform="translate(320.782 206.947)"
                fill="#fc4445"
            />
            <rect
                id="Rectangle_10"
                data-name="Rectangle 10"
                width="47.256"
                height="64.244"
                rx="4.3"
                transform="translate(388.23 162.058)"
                fill="#fff"
                stroke="#fff"
                stroke-miterlimit="10"
                stroke-width="1"
            />
            <rect
                id="Rectangle_11"
                data-name="Rectangle 11"
                width="22.645"
                height="12.022"
                rx="1.46"
                transform="translate(400.659 172.092)"
                fill="#fc4445"
                opacity="0.5"
            />
            <rect
                id="Rectangle_12"
                data-name="Rectangle 12"
                width="28.908"
                height="3.166"
                rx="1.583"
                transform="translate(397.348 195.38)"
                fill="#fc4445"
            />
            <rect
                id="Rectangle_13"
                data-name="Rectangle 13"
                width="28.908"
                height="3.166"
                rx="1.583"
                transform="translate(397.348 201.284)"
                fill="#fc4445"
            />
            <rect
                id="Rectangle_14"
                data-name="Rectangle 14"
                width="47.256"
                height="64.244"
                rx="4.3"
                transform="translate(467.763 116.42)"
                fill="#fff"
                stroke="#fff"
                stroke-miterlimit="10"
                stroke-width="1"
            />
            <rect
                id="Rectangle_15"
                data-name="Rectangle 15"
                width="30.767"
                height="14.357"
                rx="1.46"
                transform="translate(476.008 126.39)"
                fill="#fc4445"
                opacity="0.5"
            />
            <rect
                id="Rectangle_16"
                data-name="Rectangle 16"
                width="30.767"
                height="14.357"
                rx="1.46"
                transform="translate(476.008 147.256)"
                fill="#fc4445"
                opacity="0.5"
            />
            <rect
                id="Rectangle_17"
                data-name="Rectangle 17"
                width="10.093"
                height="4.43"
                rx="2.215"
                transform="translate(473.549 173.185)"
                fill="#fc4445"
            />
            <rect
                id="Rectangle_18"
                data-name="Rectangle 18"
                width="10.093"
                height="4.43"
                rx="2.215"
                transform="translate(486.348 173.185)"
                fill="#fc4445"
            />
            <rect
                id="Rectangle_19"
                data-name="Rectangle 19"
                width="10.093"
                height="4.43"
                rx="2.215"
                transform="translate(499.146 173.185)"
                fill="#fc4445"
            />
            <rect
                id="Rectangle_20"
                data-name="Rectangle 20"
                width="47.256"
                height="64.244"
                rx="4.3"
                transform="translate(467.651 207.697)"
                fill="#fff"
                stroke="#fff"
                stroke-miterlimit="10"
                stroke-width="1"
            />
            <rect
                id="Rectangle_21"
                data-name="Rectangle 21"
                width="14.122"
                height="14.357"
                rx="1.46"
                transform="translate(471.299 215.207)"
                fill="#fc4445"
                opacity="0.5"
            />
            <rect
                id="Rectangle_22"
                data-name="Rectangle 22"
                width="28.908"
                height="3.166"
                rx="1.583"
                transform="translate(476.828 238.308)"
                fill="#fc4445"
            />
            <rect
                id="Rectangle_23"
                data-name="Rectangle 23"
                width="28.908"
                height="3.166"
                rx="1.583"
                transform="translate(476.828 244.71)"
                fill="#fc4445"
            />
            <rect
                id="Rectangle_24"
                data-name="Rectangle 24"
                width="28.908"
                height="3.166"
                rx="1.583"
                transform="translate(476.828 251.112)"
                fill="#fc4445"
            />
            <path
                id="Path_16"
                data-name="Path 16"
                d="M739.939,377.31l-.723.348-5.861,2.818-.536.252a.289.289,0,0,1-.391-.155.318.318,0,0,1,0-.107V378.8a.284.284,0,0,0-.284-.284H717.9a.289.289,0,0,1-.289-.284V375.88a.289.289,0,0,1,.289-.284H732.16a.289.289,0,0,0,.284-.289v-1.7a.35.35,0,0,1,0-.107.289.289,0,0,1,.391-.15l.536.252,5.845,2.845.723.354a.284.284,0,0,1,0,.514Z"
                transform="translate(-357.158 -184.662)"
                fill="#fff"
                stroke="#fff"
                stroke-miterlimit="10"
                stroke-width="1"
            />
            <path
                id="Path_17"
                data-name="Path 17"
                d="M889.63,353.424l-.471.648-3.836,5.229-.337.466a.295.295,0,0,1-.418.048.256.256,0,0,1-.064-.086l-.782-1.468a.3.3,0,0,0-.391-.123l-12.59,6.7a.284.284,0,0,1-.386-.118l-1.1-2.084a.284.284,0,0,1,.118-.386l12.59-6.691a.284.284,0,0,0,.118-.386l-.8-1.505a.188.188,0,0,1-.032-.1.279.279,0,0,1,.273-.316h.573l6.493-.236.8-.027a.284.284,0,0,1,.241.434Z"
                transform="translate(-427.545 -175.224)"
                fill="#fff"
                stroke="#fff"
                stroke-miterlimit="10"
                stroke-width="1"
            />
            <path
                id="Path_18"
                data-name="Path 18"
                d="M889.429,395.414H881.57a.284.284,0,0,1-.284-.305.242.242,0,0,1,.027-.1l.718-1.505a.284.284,0,0,0-.134-.38l-12.857-6.172a.289.289,0,0,1-.134-.386l1.018-2.116a.279.279,0,0,1,.38-.134l12.857,6.177a.3.3,0,0,0,.386-.134l.739-1.538a.279.279,0,0,1,.477-.054l.359.445,4.034,5.1.5.632a.284.284,0,0,1-.225.471Z"
                transform="translate(-427.387 -189.753)"
                fill="#fff"
                stroke="#fff"
                stroke-miterlimit="10"
                stroke-width="1"
            />
            <path
                id="Path_19"
                data-name="Path 19"
                d="M417.8,606.441l-.043,1.57-2.732,96.468h-4.6l2.255-96.468.043-1.811Z"
                transform="translate(-190.557 -281.448)"
                fill="#504f60"
            />
            <path
                id="Path_20"
                data-name="Path 20"
                d="M417.8,606.441l-.043,1.57-2.732,96.468h-4.6l2.255-96.468.043-1.811Z"
                transform="translate(-190.557 -281.448)"
                opacity="0.1"
            />
            <path
                id="Path_21"
                data-name="Path 21"
                d="M424.186,755.207l-3.439-.011-51.135-.107-2.721-.005,1.446-4.473h55.485Z"
                transform="translate(-170.342 -348.494)"
                fill="#504f60"
            />
            <path
                id="Path_22"
                data-name="Path 22"
                d="M419.756,606.441l-.043,1.57H414.64l.043-1.811Z"
                transform="translate(-192.511 -281.448)"
                opacity="0.1"
            />
            <rect
                id="Rectangle_25"
                data-name="Rectangle 25"
                width="8.523"
                height="3.204"
                transform="translate(220.661 322.636)"
                fill="#504f60"
            />
            <path
                id="Path_23"
                data-name="Path 23"
                d="M370.067,750.61l-.455,4.479-2.721-.005,1.446-4.473Z"
                transform="translate(-170.342 -348.494)"
                opacity="0.1"
            />
            <path
                id="Path_24"
                data-name="Path 24"
                d="M372.062,602.14l-.145,1.388L360.577,715.4l-5.2-.241,11.952-111.634.134-1.27Z"
                transform="translate(-164.998 -279.563)"
                fill="#504f60"
            />
            <path
                id="Path_25"
                data-name="Path 25"
                d="M470.455,755.207l-3.439-.011-.466-4.586h3.541Z"
                transform="translate(-216.612 -348.494)"
                opacity="0.1"
            />
            <path
                id="Path_26"
                data-name="Path 26"
                d="M469.542,715.162l-5.2.241L453,603.528l-.139-1.388,4.591.118.134,1.27Z"
                transform="translate(-210.256 -279.563)"
                fill="#504f60"
            />
            <rect
                id="Rectangle_26"
                data-name="Rectangle 26"
                width="114.597"
                height="109.882"
                transform="translate(166.81 212.266)"
                fill="#fff"
            />
            <rect
                id="Rectangle_27"
                data-name="Rectangle 27"
                width="165.003"
                height="109.882"
                rx="4.13"
                transform="translate(141.674 212.266)"
                fill="#fff"
            />
            <path
                id="Path_27"
                data-name="Path 27"
                d="M463.4,346.864V373.4H458.93V346.864a2.234,2.234,0,1,1,4.468,0Z"
                transform="translate(-237.059 -171.343)"
                fill="#504f60"
            />
            <rect
                id="Rectangle_28"
                data-name="Rectangle 28"
                width="4.473"
                height="2.416"
                transform="translate(221.871 199.639)"
                opacity="0.1"
            />
            <path
                id="Path_28"
                data-name="Path 28"
                d="M463.637,396.271v8.764H455.66v-8.764a1.071,1.071,0,0,1,1.071-1.071h5.8a1.071,1.071,0,0,1,1.1,1.071Z"
                transform="translate(-235.54 -194.821)"
                fill="#504f60"
            />
            <circle
                id="Ellipse_13"
                data-name="Ellipse 13"
                cx="1.693"
                cy="1.693"
                r="1.693"
                transform="translate(222.413 204.23)"
                opacity="0.1"
            />
            <circle
                id="Ellipse_14"
                data-name="Ellipse 14"
                cx="1.693"
                cy="1.693"
                r="1.693"
                transform="translate(222.413 203.866)"
                fill="#3f3d56"
            />
            <rect
                id="Rectangle_29"
                data-name="Rectangle 29"
                width="7.977"
                height="1.088"
                transform="translate(220.12 209.127)"
                opacity="0.1"
            />
            <rect
                id="Rectangle_30"
                data-name="Rectangle 30"
                width="14.507"
                height="2.416"
                transform="translate(216.852 210.579)"
                opacity="0.1"
            />
            <rect
                id="Rectangle_31"
                data-name="Rectangle 31"
                width="14.507"
                height="2.416"
                transform="translate(216.852 209.85)"
                fill="#504f60"
            />
            <path
                id="Path_29"
                data-name="Path 29"
                d="M361.854,596H421.63a3.214,3.214,0,0,1,3.214,3.214H358.65a3.214,3.214,0,0,1,3.2-3.214Z"
                transform="translate(-166.517 -276.712)"
                opacity="0.1"
            />
            <path
                id="Path_30"
                data-name="Path 30"
                d="M377.824,602.258l4.6-.118-.145,1.388H377.69Z"
                transform="translate(-175.356 -279.563)"
                opacity="0.1"
            />
            <rect
                id="Rectangle_32"
                data-name="Rectangle 32"
                width="8.523"
                height="1.329"
                transform="translate(220.661 322.636)"
                opacity="0.1"
            />
            <path
                id="Path_31"
                data-name="Path 31"
                d="M457.585,603.528H453l-.139-1.388,4.591.118Z"
                transform="translate(-210.256 -279.563)"
                opacity="0.1"
            />
            <path
                id="Path_32"
                data-name="Path 32"
                d="M361.854,597.4H421.63a3.214,3.214,0,0,1,3.214,3.214H358.65a3.214,3.214,0,0,1,3.214-3.214Z"
                transform="translate(-166.517 -277.362)"
                fill="#504f60"
            />
            <path
                id="Path_33"
                data-name="Path 33"
                d="M543.182,441.472a.8.8,0,0,1,.916.879,106.225,106.225,0,0,0,.563,22.667c.391,3.423.921,7.045,2.438,9.584a.809.809,0,0,1-.107,1,2.143,2.143,0,0,1-1.489.445l-23.909.134a.8.8,0,0,1-.782-.986c1.923-8.2,1.511-17.143.739-25.645-.38-4.184-.6-6.429,2.523-6.723,3.214-.289,6.429-.423,9.643-.691C534.488,442.066,540.67,441.884,543.182,441.472Z"
                transform="translate(-265.778 -216.3)"
                fill="#fff"
                stroke="#33313f"
                stroke-miterlimit="10"
                stroke-width="3"
            />
            <path
                id="Path_34"
                data-name="Path 34"
                d="M524.938,533.162a.8.8,0,0,0-.916.879,105.877,105.877,0,0,1-.568,22.666c-.386,3.423-.916,7.045-2.432,9.589a.8.8,0,0,0,.107.991,2.142,2.142,0,0,0,1.489.445l23.909.139a.809.809,0,0,0,.782-.991c-1.923-8.2-1.511-17.143-.739-25.645.38-4.184.6-6.429-2.523-6.723s-6.429-.423-9.643-.691C533.633,533.756,527.451,533.574,524.938,533.162Z"
                transform="translate(-265.828 -258.869)"
                fill="#fff"
                stroke="#33313f"
                stroke-miterlimit="10"
                stroke-width="3"
            />
            <path
                id="Path_35"
                data-name="Path 35"
                d="M537.1,558.863a70.921,70.921,0,0,1,8.336.1.788.788,0,0,1,.707.7l.262,2.191a.311.311,0,0,1-.064.284.3.3,0,0,1-.2.054c-1.168.032-2.336-.1-3.5,0-.723.059-1.441.2-2.143.279a25.576,25.576,0,0,1-3.568.064,17.981,17.981,0,0,1-4.966-.611s.1-1.816.1-1.988c0-.846-.027-.921.846-.943Z"
                transform="translate(-270.965 -270.771)"
                fill="none"
                stroke="#33313f"
                stroke-linecap="round"
                stroke-miterlimit="10"
                stroke-width="2"
            />
            <path
                id="Path_36"
                data-name="Path 36"
                d="M537.1,574.158a69.777,69.777,0,0,0,8.336-.107.788.788,0,0,0,.707-.7l.262-2.186a.321.321,0,0,0-.064-.289.294.294,0,0,0-.2-.054c-1.168-.032-2.336.107-3.5,0-.723-.054-1.441-.193-2.143-.273a24.777,24.777,0,0,0-3.568-.064,17.981,17.981,0,0,0-4.966.611s.1,1.811.1,1.988c0,.846-.027.916.846.943Z"
                transform="translate(-270.965 -276.187)"
                fill="none"
                stroke="#33313f"
                stroke-linecap="round"
                stroke-miterlimit="10"
                stroke-width="2"
            />
            <path
                id="Path_37"
                data-name="Path 37"
                d="M537.1,584.523a69.764,69.764,0,0,1,8.336.107.788.788,0,0,1,.707.7l.262,2.186a.321.321,0,0,1-.064.289.3.3,0,0,1-.2.054c-1.168.032-2.336-.107-3.5,0-.723.054-1.441.2-2.143.279a25.584,25.584,0,0,1-3.568.059,17.974,17.974,0,0,1-4.966-.605s.1-1.816.1-1.993c0-.846-.027-.916.846-.937Z"
                transform="translate(-270.965 -282.684)"
                fill="none"
                stroke="#33313f"
                stroke-linecap="round"
                stroke-miterlimit="10"
                stroke-width="2"
            />
            <path
                id="Path_38"
                data-name="Path 38"
                d="M543.686,499a3.54,3.54,0,0,1,.236,1.864.278.278,0,0,1-.038.145.289.289,0,0,1-.225.08l-5.089.2s.284-1.5.321-1.607c.177-.611.621-.45,1.195-.482Z"
                transform="translate(-274.034 -243.013)"
                fill="#fff"
                stroke="#33313f"
                stroke-linecap="round"
                stroke-miterlimit="10"
                stroke-width="2"
            />
            <path
                id="Path_39"
                data-name="Path 39"
                d="M558.031,499a3.541,3.541,0,0,1,.23,1.864.273.273,0,0,1-.032.145.3.3,0,0,1-.225.08l-5.095.2s.289-1.5.327-1.607c.171-.611.616-.45,1.195-.482Z"
                transform="translate(-280.691 -243.013)"
                fill="#fff"
                stroke="#33313f"
                stroke-linecap="round"
                stroke-miterlimit="10"
                stroke-width="2"
            />
            <path
                id="Path_40"
                data-name="Path 40"
                d="M542.979,451.258l.686,5.893a1.607,1.607,0,0,1-1.462,1.773,76.505,76.505,0,0,1-13.061-.086,1.607,1.607,0,0,1-1.452-1.532,22.885,22.885,0,0,0-.461-3.123c-.177-1.071-.37-2.105.895-2.421a21.9,21.9,0,0,1,4.377-.118q2.486-.048,4.955-.139C537.916,451.5,542.979,451.232,542.979,451.258Z"
                transform="translate(-268.708 -220.847)"
                fill="none"
                stroke="#33313f"
                stroke-miterlimit="10"
                stroke-width="3"
            />
            <path
                id="Path_41"
                data-name="Path 41"
                d="M542.97,484.15l.675-5.893a1.607,1.607,0,0,0-1.463-1.773,77.058,77.058,0,0,0-13.061.08,1.607,1.607,0,0,0-1.452,1.538,22.865,22.865,0,0,1-.461,3.123c-.177,1.071-.37,2.105.895,2.421a21.9,21.9,0,0,0,4.377.118c1.655.032,3.305.075,4.955.139C537.907,483.919,542.97,484.192,542.97,484.15Z"
                transform="translate(-268.699 -232.449)"
                fill="none"
                stroke="#33313f"
                stroke-miterlimit="10"
                stroke-width="3"
            />
            <path
                id="Path_42"
                data-name="Path 42"
                d="M537.573,540.425q-.268,2.941-.338,5.893a27.893,27.893,0,0,1-5.845-.67,1.034,1.034,0,0,1-.959-1.264c-.037-1.221-.68-3.948,1.039-4.12C531.985,540.211,537.567,540.522,537.573,540.425Z"
                transform="translate(-270.186 -262.168)"
                fill="none"
                stroke="#33313f"
                stroke-linecap="round"
                stroke-miterlimit="10"
                stroke-width="2"
            />
            <path
                id="Path_43"
                data-name="Path 43"
                d="M348.9,490.6a1.709,1.709,0,0,1,1.886,1.859A109.328,109.328,0,0,0,351.462,514c.359,3.161.836,6.488,2.1,8.979a1.4,1.4,0,0,1-1.243,2.047h0l-23.722.139a.943.943,0,0,1-.927-1.146c1.88-8.154,1.468-17.057.7-25.49-.386-4.184-.6-6.429,2.518-6.723,3.214-.284,6.429-.418,9.616-.691C341.187,491.062,346.051,490.912,348.9,490.6Z"
                transform="translate(-176.108 -239.109)"
                fill="#fff"
                stroke="#33313f"
                stroke-linecap="round"
                stroke-miterlimit="10"
                stroke-width="3"
            />
            <path
                id="Path_44"
                data-name="Path 44"
                d="M425.374,490.351a.852.852,0,0,0-.975.943,106.25,106.25,0,0,1-.573,22.591c-.386,3.423-.916,7.029-2.427,9.573a.846.846,0,0,0,.225,1.109,2.245,2.245,0,0,0,1.361.343l23.845.139a.857.857,0,0,0,.83-1.071c-1.9-8.18-1.495-17.116-.723-25.581.38-4.184.6-6.429-2.518-6.723-3.214-.284-6.429-.418-9.643-.691C433.988,490.935,427.934,490.759,425.374,490.351Z"
                transform="translate(-219.579 -238.993)"
                fill="#fff"
                stroke="#33313f"
                stroke-miterlimit="10"
                stroke-width="3"
            />
            <line
                id="Line_2"
                data-name="Line 2"
                x2="12.129"
                y2="16.179"
                transform="translate(158.592 256.228)"
                fill="#fff"
                stroke="#33313f"
                stroke-linecap="round"
                stroke-miterlimit="10"
                stroke-width="2"
            />
            <line
                id="Line_3"
                data-name="Line 3"
                x1="11.727"
                y2="18.199"
                transform="translate(158.994 255.017)"
                fill="#fff"
                stroke="#33313f"
                stroke-linecap="round"
                stroke-miterlimit="10"
                stroke-width="2"
            />
            <line
                id="Line_4"
                data-name="Line 4"
                x2="6.605"
                y2="8.813"
                transform="translate(212.716 256.989)"
                fill="#fff"
                stroke="#33313f"
                stroke-linecap="round"
                stroke-miterlimit="10"
                stroke-width="2"
            />
            <line
                id="Line_5"
                data-name="Line 5"
                x1="6.386"
                y2="9.916"
                transform="translate(212.936 256.324)"
                fill="#fff"
                stroke="#33313f"
                stroke-linecap="round"
                stroke-miterlimit="10"
                stroke-width="2"
            />
            <path
                id="Path_45"
                data-name="Path 45"
                d="M343.838,539.233a71.024,71.024,0,0,1,8.341.1.8.8,0,0,1,.707.7l.257,2.191a.338.338,0,0,1-.059.289.376.376,0,0,1-.2.054c-1.168.032-2.341-.107-3.5,0-.723.054-1.436.193-2.143.273a25.558,25.558,0,0,1-3.568.064,17.91,17.91,0,0,1-4.961-.611c-.032,0,.1-1.811.1-1.988,0-.846-.032-.916.841-.943Z"
                transform="translate(-181.239 -261.657)"
                fill="none"
                stroke="#33313f"
                stroke-linecap="round"
                stroke-miterlimit="10"
                stroke-width="2"
            />
            <path
                id="Path_46"
                data-name="Path 46"
                d="M438.954,524.133a69.866,69.866,0,0,1,8.341.107.788.788,0,0,1,.707.7l.257,2.186a.338.338,0,0,1-.059.289.3.3,0,0,1-.2.054c-1.168.032-2.341-.107-3.5,0-.723.054-1.436.2-2.143.273a24.777,24.777,0,0,1-3.568.064,17.986,17.986,0,0,1-4.966-.611c-.027,0,.1-1.811.1-1.988,0-.846-.027-.916.846-.943Z"
                transform="translate(-225.397 -254.646)"
                fill="none"
                stroke="#33313f"
                stroke-linecap="round"
                stroke-miterlimit="10"
                stroke-width="2"
            />
            <path
                id="Path_47"
                data-name="Path 47"
                d="M443.277,535.463a70.932,70.932,0,0,0-8.336.1.788.788,0,0,0-.707.7l-.246,2.186a.305.305,0,0,0,.059.289.3.3,0,0,0,.2.054c1.168.032,2.336-.1,3.5,0,.723.054,1.436.2,2.143.279a26.429,26.429,0,0,0,3.568.064,17.932,17.932,0,0,0,4.966-.611c.027,0-.1-1.816-.1-1.988,0-.852.032-.921-.841-.943Z"
                transform="translate(-225.472 -259.907)"
                fill="none"
                stroke="#33313f"
                stroke-linecap="round"
                stroke-miterlimit="10"
                stroke-width="2"
            />
            <path
                id="Path_48"
                data-name="Path 48"
                d="M386.63,527.975s7.88-.4,9.295,0"
                transform="translate(-203.491 -256.383)"
                fill="none"
                stroke="#33313f"
                stroke-miterlimit="10"
                stroke-width="3"
            />
            <path
                id="Path_49"
                data-name="Path 49"
                d="M403.123,520.21s3.434,3.434,3.434,4.243-4.447,3.638-4.447,3.638"
                transform="translate(-210.678 -252.861)"
                fill="none"
                stroke="#33313f"
                stroke-miterlimit="10"
                stroke-width="3"
            />
            <path
                id="Path_50"
                data-name="Path 50"
                d="M482.84,511.414s7.141-3.343,8.6-3.5"
                transform="translate(-248.159 -247.15)"
                fill="none"
                stroke="#33313f"
                stroke-miterlimit="10"
                stroke-width="3"
            />
            <path
                id="Path_51"
                data-name="Path 51"
                d="M495.92,500.58s4.473,1.886,4.779,2.63-2.743,5.047-2.743,5.047"
                transform="translate(-254.232 -243.747)"
                fill="none"
                stroke="#33313f"
                stroke-miterlimit="10"
                stroke-width="3"
            />
            <path
                id="Path_52"
                data-name="Path 52"
                d="M482.7,530.43s7.259,3.08,8.357,4.066"
                transform="translate(-248.094 -257.605)"
                fill="none"
                stroke="#33313f"
                stroke-miterlimit="10"
                stroke-width="3"
            />
            <path
                id="Path_53"
                data-name="Path 53"
                d="M497.985,530.89s1.607,4.591,1.232,5.32-5.588,1.329-5.588,1.329"
                transform="translate(-253.169 -257.819)"
                fill="none"
                stroke="#33313f"
                stroke-miterlimit="10"
                stroke-width="3"
            />
            <path
                id="Path_54"
                data-name="Path 54"
                d="M530.852,499a3.54,3.54,0,0,1,.23,1.864.272.272,0,0,1-.032.145.3.3,0,0,1-.225.08l-5.095.2s.289-1.5.327-1.607c.171-.611.621-.45,1.195-.482Z"
                transform="translate(-268.072 -243.013)"
                fill="#fff"
                stroke="#33313f"
                stroke-linecap="round"
                stroke-miterlimit="10"
                stroke-width="2"
            />
            <path
                id="Path_55"
                data-name="Path 55"
                d="M310.308,562.511l1.8-1.677-1.607-.064-.841,1.291Z"
                transform="translate(-143.772 -260.356)"
                fill="#33313f"
            />
            <path
                id="Path_56"
                data-name="Path 56"
                d="M286.7,575.085l1.907-2.448.423-.439,5.839-6.118L296.857,564l.932-.112,1.339-1.329.788.691-1.3,1.521.021.8-2.03,2.2L290.873,574l-.37.4-2.3,2.132Z"
                transform="translate(-133.112 -261.187)"
                fill="#65617d"
            />
            <path
                id="Path_57"
                data-name="Path 57"
                d="M291.05,575.248l5.839-6.118,1.736,1.693-5.732,6.225Z"
                transform="translate(-135.132 -264.237)"
                fill="#33313f"
            />
            <path
                id="Path_58"
                data-name="Path 58"
                d="M289.109,478.67a25.716,25.716,0,0,1-4.388-5.357c-2.571-4.007-6.868-6.172-9.814-7.248v-1.3l.182-.038-.08-1.211h.08l-.209-3.214v-.027h0l-1.061-15.948s-3.777-10.634,2.936-20.443.418-19.216-5.893-22.9c0,0-2.936-15.536-.418-19.216a109.112,109.112,0,0,0-10.543-5.331c-.059-.193-.107-.386-.155-.589-.07-1.446-.161-2.893-.241-4.339a15.788,15.788,0,0,1,.413-2.229c.08-.305.171-.611.268-.927a15.954,15.954,0,0,0,10.259-14.791,15.535,15.535,0,0,0-1-5.486,6.9,6.9,0,0,0,.766-1.789,7.078,7.078,0,0,0-1-5.1c-1.682-3.632-5.877-7.034-8.92-8.293-4.361-1.768-10.923-1.35-15.466-.8-2.55.305-5.459,1.157-6.38,3.493a24.345,24.345,0,0,0-.579,2.679c-.954,3.568-5.073,5.229-7.618,7.961-2.25,2.411-3.214,5.673-3.964,8.85a81.007,81.007,0,0,0-1.473,27.777c.257,2.143.6,4.259.964,6.38-1.532,2.143-2.914,5.93-2.282,12.509l-5.036,23.711-2.095,13.9a12.108,12.108,0,0,0,3.75,10.629,93.324,93.324,0,0,1,9.493,11.839,28.1,28.1,0,0,0-1.527,9.423c.054,1.457.134,2.866.236,4.205a37.141,37.141,0,0,0,1.607,8.379c.589,5.711,1.752,13.013,4.029,17.679l.836,24.938s-1.259,22.9-3.75,27.392,5.448,42.52,5.448,42.52v5.314L235.15,605.6l-.338-.252c-6.289,7.768,0,10.629,0,10.629s1.714.08,4.452.187a5.014,5.014,0,0,0,2.679,5.539s35.642,1.634,41.936,0,4.613-7.361,1.254-6.964a15.253,15.253,0,0,1-5.02-.654c1.955-2.277.391-5.357-2.105-5.068-3.354.407-10.9-2.561-10.9-2.561l-.038.048a5.627,5.627,0,0,0-.739-.225,83.928,83.928,0,0,1-8.3-2.32c-1.607-3.445-5.357-12.7-2.679-19.881,1.211-3.247,1.607-7.564,1.607-11.754a133.4,133.4,0,0,1,5.523-37.319l5.03-17.143s7.13-20.856,7.548-26.577l-.027-8.325c5.893,3.75,10.581,2.625,13.57.873a3.214,3.214,0,0,0,.5-5.17Zm-55.967-43.394c.145-.6.263-1.254.364-1.945.986,0,1.977,0,2.963-.032a15.091,15.091,0,0,1,1.489,8.036c-2.271-.7-5.668-2.421-4.816-6.032Z"
                transform="translate(-124.377 -165.302)"
                fill="url(#linear-gradient)"
            />
            <path
                id="Path_59"
                data-name="Path 59"
                d="M253.847,829.182,252.24,834l5.245,5.245,17.352,4.034,12.509-1.211-.809-4.441h0a5.357,5.357,0,0,0-3.975-3.905c-5.143-1.211-14.947-3.964-17.009-7.795Z"
                transform="translate(-141.097 -394.799)"
                fill="#db8b8b"
            />
            <path
                id="Path_60"
                data-name="Path 60"
                d="M253.847,829.182,252.24,834l5.245,5.245,17.352,4.034,12.509-1.211-.809-4.441h0a5.357,5.357,0,0,0-3.975-3.905c-5.143-1.211-14.947-3.964-17.009-7.795Z"
                transform="translate(-141.097 -394.799)"
                opacity="0.1"
            />
            <path
                id="Path_61"
                data-name="Path 61"
                d="M280.97,846.808l-2.421-3.214,2.421-3.75s7.259,2.93,10.489,2.529,4.822,5.245-1.211,6.857-40.351,0-40.351,0-6.054-2.823,0-10.489l5.245,4.034s8.877,4.822,13.318,4.034Z"
                transform="translate(-138.761 -400.746)"
                fill="#2e3037"
            />
            <path
                id="Path_62"
                data-name="Path 62"
                d="M300.122,852.631l-12.509,1.211L270.261,849.8,265,844.557l.429-1.28.6-1.8.589-1.763,11.7-3.214a7.233,7.233,0,0,0,2.373,2.373c3.8,2.6,10.629,4.479,14.636,5.416.166.043.338.091.5.15a5.314,5.314,0,0,1,3.477,3.75Z"
                transform="translate(-147.022 -399.706)"
                fill="#db8b8b"
            />
            <path
                id="Path_63"
                data-name="Path 63"
                d="M300.122,858.5l-12.509,1.211-17.352-4.039L265,850.43l.429-1.28,4.822,3.7s8.882,4.822,13.318,4.034h12.5l-2.421-3.214,2.175-3.359a5.314,5.314,0,0,1,3.477,3.75Z"
                transform="translate(-147.022 -405.579)"
                opacity="0.1"
            />
            <path
                id="Path_64"
                data-name="Path 64"
                d="M293.74,857.358l-2.421-3.214,2.421-3.75s7.264,2.93,10.489,2.529,4.822,5.245-1.205,6.863-40.356,0-40.356,0-6.054-2.829,0-10.495l5.245,4.034s8.882,4.822,13.318,4.039Z"
                transform="translate(-144.69 -405.644)"
                fill="#2e3037"
            />
            <path
                id="Path_65"
                data-name="Path 65"
                d="M287.416,408.946s-19.366,2.014-16.543-6.054a14.465,14.465,0,0,0,.595-7.125,19.773,19.773,0,0,0-3.418-8.207l18.156,2.823a22.671,22.671,0,0,0-2.459,5.829C281.582,404.778,287.416,408.946,287.416,408.946Z"
                transform="translate(-148.438 -191.274)"
                fill="#db8b8b"
            />
            <path
                id="Path_66"
                data-name="Path 66"
                d="M242.44,599.21s.4,17.352,4.436,25.827l.809,24.611s-1.211,22.6-3.632,27.038,5.245,41.969,5.245,41.969v6.456s12.911,4.821,16.543-.809l-3.632-48.017,1.607-66.58Z"
                transform="translate(-136.547 -289.538)"
                fill="#35323e"
            />
            <path
                id="Path_67"
                data-name="Path 67"
                d="M242.43,599.21s.4,17.352,4.436,25.827l.809,24.611s-1.211,22.6-3.632,27.038,5.245,41.969,5.245,41.969v6.456s12.911,4.821,16.543-.809L262.2,676.285l1.607-66.58Z"
                transform="translate(-136.543 -289.538)"
                opacity="0.1"
            />
            <path
                id="Path_68"
                data-name="Path 68"
                d="M223.42,495.27,221,501.725l-2.014,13.72a12.134,12.134,0,0,0,3.632,10.489c4.822,4.822,11.3,14.931,11.3,14.931l7.666-23s-7.666-1.211-6.429-6.455.8-14.931.8-14.931Z"
                transform="translate(-125.601 -241.281)"
                fill="#fc4445"
            />
            <path
                id="Path_69"
                data-name="Path 69"
                d="M281.613,838.863c-5.18,8.148-13.232,3.53-14.663,2.614l.589-1.763,11.7-3.214A7.233,7.233,0,0,0,281.613,838.863Z"
                transform="translate(-147.927 -399.706)"
                opacity="0.1"
            />
            <path
                id="Path_70"
                data-name="Path 70"
                d="M285.368,589.772c-.407,5.647-7.264,26.25-7.264,26.25l-4.822,16.934a134.623,134.623,0,0,0-5.347,36.815,35.957,35.957,0,0,1-1.543,11.6c-3.214,8.877,3.214,20.984,3.214,20.984-5.647,10.088-15.332,3.214-15.332,3.214V699.14s-7.645-35.122-5.625-41.979,2.829-25.426,2.829-25.426V598.247A37.983,37.983,0,0,1,240.4,574.139c-.1-1.323-.177-2.711-.225-4.152-.327-9.107,5.893-19.779,8.325-23.572.589-.922.954-1.436.954-1.436l35.62,14.132.08.032v4.43Z"
                transform="translate(-135.492 -264.361)"
                fill="#35323e"
            />
            <path
                id="Path_71"
                data-name="Path 71"
                d="M286.206,390.383a22.673,22.673,0,0,0-2.459,5.829,15.761,15.761,0,0,1-12.279-.445,19.774,19.774,0,0,0-3.418-8.207Z"
                transform="translate(-148.438 -191.274)"
                opacity="0.1"
            />
            <circle
                id="Ellipse_15"
                data-name="Ellipse 15"
                cx="15.74"
                cy="15.74"
                r="15.74"
                transform="translate(113.961 173.684)"
                fill="#db8b8b"
            />
            <path
                id="Path_72"
                data-name="Path 72"
                d="M291.326,563.543l-.177.032C274.231,567.052,253,549.421,253,549.421a20.356,20.356,0,0,0,1.468-3.005c.589-.921.954-1.436.954-1.436l35.615,14.132.091,1.409Z"
                transform="translate(-141.45 -264.361)"
                opacity="0.1"
            />
            <path
                id="Path_73"
                data-name="Path 73"
                d="M272.905,418.552s-22.6-13.72-29.861-8.073L232.956,423.39s-6.455,2.02-5.245,14.931l-4.822,23.406,14.523,3.632s8.475,8.068,1.607,19.774c0,0,21.386,17.754,38.336,14.122L276.136,480.3s-3.632-10.495,2.823-20.175.4-18.965-5.647-22.6C273.307,437.517,270.484,422.179,272.905,418.552Z"
                transform="translate(-127.471 -201.282)"
                fill="#fc4445"
            />
            <path
                id="Path_74"
                data-name="Path 74"
                d="M314.923,580.38s8.475,1.607,12.509,8.073a25.105,25.105,0,0,0,4.227,5.277,3.214,3.214,0,0,1-.5,5.1c-3.214,1.929-8.422,3.112-15-2.309C304.835,587.243,314.923,580.38,314.923,580.38Z"
                transform="translate(-168.278 -280.796)"
                fill="#db8b8b"
            />
            <path
                id="Path_75"
                data-name="Path 75"
                d="M281.51,440.74s-8.068-.809-10.088,20.175-5.245,25.822-5.245,25.822-2.02,10.9,2.823,17.352,14.122,20.224,14.122,20.224,10.093,2.829,12.107-7.264l-12.509-23.4s-2.014-8.073,2.823-13.72S294.02,436.722,281.51,440.74Z"
                transform="translate(-147.375 -215.825)"
                opacity="0.1"
            />
            <path
                id="Path_76"
                data-name="Path 76"
                d="M283.02,438.517s-8.073-.809-10.088,20.175-5.245,25.822-5.245,25.822-2.02,10.907,2.823,17.336,14.122,20.175,14.122,20.175,10.088,2.829,12.107-7.259l-12.509-23.406s-2.02-8.073,2.823-13.72S295.519,434.478,283.02,438.517Z"
                transform="translate(-148.076 -214.792)"
                fill="#fc4445"
            />
            <path
                id="Path_77"
                data-name="Path 77"
                d="M304.288,467s.8,12.509-2.829,19.372-3.632,15.734,0,20.577"
                transform="translate(-162.684 -228.156)"
                opacity="0.1"
            />
            <path
                id="Path_78"
                data-name="Path 78"
                d="M290.482,432.467s-3.214-3.214-12.509-3.632-13.313-3.214-13.313-3.214"
                transform="translate(-146.864 -208.945)"
                opacity="0.1"
            />
            <path
                id="Path_79"
                data-name="Path 79"
                d="M249.1,336.386c-2.454.3-5.255,1.141-6.139,3.45a23.713,23.713,0,0,0-.562,2.679c-.916,3.525-4.88,5.164-7.329,7.859-2.143,2.379-3.113,5.6-3.814,8.732a82.036,82.036,0,0,0-1.441,27.4c.788,6.691,2.395,13.275,2.85,20s-.375,13.795-4.05,19.441c-1.607,2.432-3.664,4.564-4.784,7.238,2.314,1.875,5.507,2.143,8.486,2.245q5.207.177,10.414,0a11.109,11.109,0,0,0,5.293-1.1,8.2,8.2,0,0,0,2.78-2.979c1.982-3.37,2.486-7.42,2.4-11.331s-.7-7.795-.777-11.706c-.038-2.143.129-4.393,1.355-6.139a19.991,19.991,0,0,1,3.38-3.129c4.323-3.825,5.8-9.964,5.947-15.734s-.8-11.55-.316-17.3c.188-2.255,1.029-4.955,3.246-5.395a11.434,11.434,0,0,0,2.105-.268c1.779-.68,1.607-3.188,2.111-5.02.573-2.062,2.33-3.654,2.823-5.732,1.163-4.88-5.052-10.714-9.107-12.407C259.766,335.427,253.45,335.85,249.1,336.386Z"
                transform="translate(-127.907 -167.299)"
                opacity="0.1"
            />
            <path
                id="Path_80"
                data-name="Path 80"
                d="M248.352,334.887c-2.454.3-5.255,1.141-6.139,3.445a24.646,24.646,0,0,0-.536,2.679c-.916,3.525-4.886,5.164-7.334,7.864-2.143,2.373-3.113,5.593-3.814,8.732a82.142,82.142,0,0,0-1.414,27.413c.788,6.691,2.395,13.281,2.845,20s-.37,13.795-4.045,19.441c-1.607,2.432-3.664,4.564-4.784,7.238,2.314,1.88,5.507,2.143,8.486,2.245q5.207.177,10.414,0a11.046,11.046,0,0,0,5.293-1.1,8.105,8.105,0,0,0,2.775-2.979c1.988-3.37,2.486-7.42,2.405-11.331s-.707-7.795-.777-11.706c-.038-2.143.129-4.393,1.35-6.134a19.791,19.791,0,0,1,3.386-3.129c4.318-3.83,5.8-9.97,5.947-15.74s-.809-11.55-.321-17.3c.193-2.25,1.034-4.955,3.247-5.389a11,11,0,0,0,2.111-.273c1.779-.68,1.607-3.182,2.111-5.02.568-2.063,2.325-3.648,2.823-5.727,1.163-4.886-5.052-10.714-9.107-12.413C259.034,333.933,252.723,334.346,248.352,334.887Z"
                transform="translate(-127.582 -166.603)"
                fill="#35323e"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: "HeroSectionAnimateImage",
};
</script>

<style scoped></style>
