import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b47524de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hero-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_header = _resolveComponent("the-header")!
  const _component_hero_section_animate_image = _resolveComponent("hero-section-animate-image")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_the_header, { class: "hero-section__header" }),
    _createVNode(_component_hero_section_animate_image, { class: "hero-section__image" })
  ]))
}